import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { usePatch, useFetching } from "../../auth/apicalls";
import { showErrorAlert, showSuccessAlert } from "../../alert/ErrorAlert";
import { useQueryClient } from "react-query";

function AnalogiaBesorolasModal({ showModal, setShowModal, analogia }) {
  const queryClient = useQueryClient();

  // Dynamically create formData with aanalogia_tipus as key
  const [formData, setFormData] = useState({
    [analogia?.aanalogia_tipus || 'absztrakt_analogia']: '',
    csoport: '',
  });

  let endpoint = '';

  if (analogia?.aanalogia_tipus) {
    endpoint = (() => {
      switch (analogia.aanalogia_tipus) {
        case "bolygo":
          return "bolygok";
        case "haz":
          return "hazak";
        case "jegy":
          return "jegyek";
        default:
          console.warn("Invalid analogia_tipus:", analogia.aanalogia_tipus);
          return '';
      }
    })();
  }

  const { data: aaData, isError, isLoading } = useFetching(endpoint ? `/api/${endpoint}/` : null);

  const { mutate: postBesorolatlanAnalogia } = usePatch(`/api/besorolatlan_analogia/${analogia.id}/`, {
    onSuccess: () => {
      queryClient.invalidateQueries('/api/besorolatlan_analogia/');
      setShowModal(false);
      showSuccessAlert({ title: "Sikerült módosítani az analógia javaslatot" });
    },
    onError: (error) => {
      setShowModal(false);
      showErrorAlert({
        title: "Nem sikerült módosítani az analógia javaslatot!",
        text: error.response.data.user_error
      });
    },
  });

  const [csoportEndpoint, setCsoportEndpoint] = useState(null);
  const { data: csoportData, isLoading: isCsoportLoading } = useFetching(csoportEndpoint);

  useEffect(() => {
    if (formData[analogia?.aanalogia_tipus || 'absztrakt_analogia']) {
      setCsoportEndpoint(`/api/analogia/${formData[analogia?.aanalogia_tipus || 'absztrakt_analogia']}/get_csoportok/`);
    } else {
      setCsoportEndpoint(null);
    }
  }, [formData, analogia?.aanalogia_tipus]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    postBesorolatlanAnalogia(formData);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Analógia besorolása</Modal.Title>
      </Modal.Header>
      <div className='text-center text-secondary mt-2' style={{fontSize:"0.8rem", color: 'var(--bs-dark)'}}>
        <p>Ha itt besoroláson keresztül töltesz föl analógiát, extra 3 pontot kapsz a 10 mellé.</p>
      </div>
      <Modal.Body>
        <div>
          <p><strong>(Absztrakt) analógia típus:</strong> {analogia.aanalogia_tipus}</p>
          <p><strong>Tartalom:</strong> {analogia.tartalom}</p>
        </div>

        <Form>
          <Form.Group controlId="absztrakt_analogia" className="mt-3">
            <Form.Label>Absztrakt analógia</Form.Label>
            {isLoading ? (
              <p>Loading options...</p>
            ) : isError ? (
              <p>Error loading options</p>
            ) : (
              <Form.Control
                as="select"
                name={analogia.aanalogia_tipus || 'absztrakt_analogia'}
                value={formData[analogia?.aanalogia_tipus || 'absztrakt_analogia']}
                onChange={handleInputChange}
              >
                <option value="">Válassz absztrakt analógiát</option>
                {aaData.aanalogia_list && aaData.aanalogia_list.map((item) => (
                  <option key={item.nevid} value={item.id}>{item.nevid}</option>
                ))}
              </Form.Control>
            )}
          </Form.Group>

          <Form.Group controlId="csoport" className="mt-3">
            <Form.Label>Csoport</Form.Label>
            <Form.Control
              as="select"
              name="csoport"
              value={formData.csoport}
              onChange={handleInputChange}
              disabled={!formData[analogia?.aanalogia_tipus || 'absztrakt_analogia'] || isCsoportLoading}
            >
              <option value="">Válassz csoportot</option>
              {isCsoportLoading ? (
                <option>Loading...</option>
              ) : (
                csoportData?.map((csoport) => (
                  <option key={csoport.id} value={csoport.id}>{csoport.nev}</option>
                ))
              )}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Mégse
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Besorolás az adatbázisba
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AnalogiaBesorolasModal;
