import React, { useState } from "react";
import ChatAnimation from "./ChatAnimation";
import FunkciokListaja from "./FunkciokListaja";
import JoinModal from "./JoinModal";
import "./backgroundAnimation.css"; // Importáljuk a CSS fájlt

const KezdolapPage = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="animated-background-kezdolap">
            <div className="p-5">
                <header className="my-5">
                    <h1 className="myheader text-center mt-5 display-1">MindenEgység</h1>
                </header>
                <ChatAnimation className="my-5"/>
                {/* <div className="text-center my-5">
                    <Link
                        to="story"
                        smooth={true}
                        duration={500}
                        className="btn btn-primary mx-2"
                    >
                        Tudj meg többet
                    </Link>
                    <Button className="mx-2" variant="success" onClick={handleShowModal}>
                        Csatlakozz hozzánk
                    </Button>
                </div> */}
            </div>
            <FunkciokListaja/>
            {/* <div id="story" className="p-5 text-center justify-content-center">
                <h3>Az én történetem</h3>
                <p>
                    Szeretném megmutatni neked azt az eszközt, ami egy teljesen racionális rendszerrel megváltoztatta az életem és a teljes látásmódom.
                    Öt éve teljesen kilátástalannak éreztem az életem, de mondhatni szerencsém volt, olyan helyzetbe keveredtem, ami átterelt a gyógyulás útjára.
                    Sajnos nagyon kevés embernek adatik meg a lehetőség, hogy tisztán láthassa életét, emiatt sokan egy folyamatos ördögi körben őrölik magunkat.
                    Ha megértjük az életében a miérteket, válaszokat kapunk, feloldódnak a nehéz érzéseink is és eltűnik minden, amit rossznak tartottunk.
                    Biztosíthatom, nem tettél semmit rosszul. Nem voltál tudatában rengeteg információnak, ami értelmet adott volna mindennek.
                </p>
            </div>
            <div className="p-5">
                <div className="p-3 text-center">
                    <h3>Tudás szerzése</h3>
                    Az asztrovárosban lépésről lépésre elsajátíthatod, azt a tudást, amin keresztül megértheted rejtett összefüggéseket életedben.
                </div>
                <div className="p-3 text-center">
                    <h3>Közösség</h3>
                    Hangsúlyt fektetünk, hogy mindenki hozzátehesse a magáét a nagy egészhez.
                </div>
                <div className="p-3 text-center">
                    <h3>Játékos fejlődés</h3>
                    Legyen egy játék az élet! Váltsd le a hasztalan social media görgetéseket egy játékos tanulásra!
                </div>
            </div> */}
            <JoinModal show={showModal} handleClose={handleCloseModal} />
        </div>
    );
}

export default KezdolapPage;
