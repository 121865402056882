import React, { useState } from 'react';
import './SzemelyKepek.css';
import { KukaIcon } from '../../utils/Ikonok';
import { useDeleteDynamic } from "../auth/apicalls";
import { useQueryClient } from "react-query";
import { showErrorAlert, showConfirmAlert } from "../alert/ErrorAlert";


function SzemelyKepek({ kepek, szemelyId }) {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const queryClient = useQueryClient();

  const handleImageClick = (kepUrl) => {
    setFullscreenImage(fullscreenImage === kepUrl ? null : kepUrl);
  };

  const { mutate: deletekep } = useDeleteDynamic(null, {
    onSuccess: () => {
      queryClient.invalidateQueries(`/api/szemely/${szemelyId}/`);
    },
    onError: (error) => {
      showErrorAlert({
        title: "Nem sikerült törölni az analógia javaslatot!",
        text: error.response?.data?.user_error || "Ismeretlen hiba történt.",
      });
    },
  });

  const handleDelete = async (kepId) => {
    const confirmed = await showConfirmAlert({
      title: "Biztosan törölni szeretnéd?",
      text: "Ez a művelet nem vonható vissza!",
    });

    if (confirmed) {
      deletekep({ dynamicUrl: `/api/szemelykep/${kepId}/` });
    }
  };

  return (
    <div className="szemely-kepek mt-4">
      <div className="image-grid">
        {kepek.map((kep, index) => (
          <div key={kep.id} className="image-container">
            <img
              src={kep.url}
              alt={`Személy kép ${index + 1}`}
              onClick={() => handleImageClick(kep.url)}
              className={fullscreenImage === kep.url ? "fullscreen" : ""}
            />
            <button className="delete-icon" onClick={() => handleDelete(kep.id)}>
              <KukaIcon />
            </button>
          </div>
        ))}
      </div>
      {fullscreenImage && (
        <div
          className="fullscreen-overlay"
          onClick={() => setFullscreenImage(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.9)",
            zIndex: 999,
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
}

export default SzemelyKepek;
