import React, { useState } from 'react';
import './RatingScale.css';
import ErtekeloSav from './arcolvasas/ErtekeloSav';

function RatingScale({ aoszempont, onRatingSelect }) {
  const {
    nev,
    erzelmi_oldal_ertekeles,
    logikai_oldal_ertekeles,
    ertekeles_tipus,
    vegpont_1_tooltip,
    vegpont_2_tooltip,
    kulonvalasztott_oldalak
  } = aoszempont;

  const isTwoWayScale = ertekeles_tipus === '2 irányú skála';
  const areValuesDifferent = erzelmi_oldal_ertekeles !== logikai_oldal_ertekeles;
  const defaultDoubleView =
  (erzelmi_oldal_ertekeles && !logikai_oldal_ertekeles) ||
  (!erzelmi_oldal_ertekeles && logikai_oldal_ertekeles) ||
  (erzelmi_oldal_ertekeles && logikai_oldal_ertekeles && areValuesDifferent) ||
  kulonvalasztott_oldalak;
  const [isDoubleView, setIsDoubleView] = useState(defaultDoubleView);
  const handleViewToggle = () => {
    setIsDoubleView((prev) => !prev);
  };

  return (
    <div className="rating-scale">
      <div className="header-row">
        <p className="question">{nev}</p>
        <button className="toggle-button"
              onClick={handleViewToggle}
              disabled={areValuesDifferent}
              aria-disabled={areValuesDifferent}>
          {isDoubleView ? 'Érzelmi és logikai oldal egységesítése' : 'Érzelmi és logikai oldal különválasztása'}
        </button>
      </div>

      {isDoubleView ? (
        <>
          <div className="scale-section">
            <div className="oldal">Érzelmi oldal</div>
            <div className="scale-container">
              <ErtekeloSav {...{isTwoWayScale, onRatingSelect}} ertekeles={erzelmi_oldal_ertekeles} side="erzelmi"/>
            </div>
          </div>
          <div className="scale-section">
            <div className="oldal">Logikai oldal</div>
            <div className="scale-container">
            <ErtekeloSav {...{isTwoWayScale, onRatingSelect}} ertekeles={logikai_oldal_ertekeles} side="logikai"/>
            </div>
          </div>
        </>
      ) : (
        <div className="scale-container">
            <ErtekeloSav {...{isTwoWayScale, onRatingSelect}} ertekeles={erzelmi_oldal_ertekeles} side="egyforma"/>
        </div>
      )}

      <div className="labels">
        <span
          className={`label left ${!isTwoWayScale ? 'secondary-text' : ''}`}
          style={!isTwoWayScale ? { color: 'gray' } : {}}
        >
          {vegpont_1_tooltip || ''}
        </span>
        <span className="label center">{isTwoWayScale ? 'kiegyensúlyozott' : 'Nincs jele'}</span>
        <span className="label right">{vegpont_2_tooltip || ''}</span>
      </div>
    </div>
  );
}

export default RatingScale;
