// SzemelyList.jsx
import React, { useState } from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './SzemelyList.css'; // Külön CSS a hover hatás kezelésére

function SzemelyList({ szemelyek }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const navigateToDetails = (szemelyId) => {
    navigate(`/szemely/${szemelyId}`);
  };

  return (
    <>
      {szemelyek && (
        <Table striped bordered hover className="szemely-list mt-4">
          <thead>
            <tr>
              <th>Név</th>
              <th>Feltöltő</th>
              <th>Láthatóság</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
            {szemelyek.map((szemely, index) => (
              <tr key={szemely.id}>
                <td
                  style={{ cursor: 'pointer', color: 'var(--bs-primary)' }}
                  onClick={() => navigateToDetails(szemely.id)}
                >
                  {szemely.full_name}
                </td>
                <td>{szemely.feltolto}</td>
                <td>
                  <Badge pill variant={szemely.global_visibility ? 'success' : 'warning'}>
                    {szemely.global_visibility == 'public' ? 'Publikus' :
                    szemely.global_visibility == 'anonym' ? 'Anonim':
                    szemely.global_visibility == 'anonym' ? 'Privát': 'error'}
                  </Badge>
                </td>
                <td>
                  <Button
                    variant="link"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className="text-decoration-none"
                  >
                    Részletek
                  </Button>
                  {hoveredIndex === index && (
                    <div className="hover-popup">
                      <p><strong>Közismert:</strong> {szemely.kozismert ? 'Igen' : 'Nem'}</p>
                      <p><strong>Önmaga:</strong> {szemely.onmaga ? 'Igen' : 'Nem'}</p>
                      <p><strong>Tulajdonos:</strong> {szemely.owner}</p>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default SzemelyList;
