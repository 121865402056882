import { InfoIkon } from "../../utils/Ikonok";
import React, { useContext } from 'react';
import AuthContext from "../userkezeles/AuthContext";
import "./backgroundAnimation.css";


export const FeatueElem = ({icon, toggleCollapse,toggleszoveg,  collapseBoolean, title, leiras, info, szukseges_szint}) => {
    let {user} = useContext(AuthContext);

    return <div
    className="col-lg-3 col-md-6 mx-4 mt-4 card text-center" style={{ backgroundColor: "var(--bs-lazac)" }}>
        <div className="card-body text-center">
            <h5 style={{ color: "var(--bs-text-spring)" }}>{title}</h5>
            <div className="my-4">{icon}</div>
            <p className="card-text">{leiras}</p>
            {user && szukseges_szint && <div className="text-center" style={{fontSize:'80%'}}>
                {szukseges_szint}. szinttől elérhető
            </div>}
            <button className="info-icon-container btn btn-outline-dark text-right" onClick={() => toggleCollapse(toggleszoveg)}>
                <InfoIkon />
            </button>
        </div>
        <div className={`collapse ${collapseBoolean? "show" : ""}`}>
            <div className="card py-1 px-2 m-1 mb-3 animated-background-info">
                <p className="card-text">
                    {info}
                </p>
            </div>
        </div>
        </div>
};