import React, { useState, useEffect } from 'react';

const ChatAnimation = () => {
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(40);
  const [deletingSpeed, setDeletingSpeed] = useState(10);
  const messages = [
    "Dekód a világ összes szimbólumát!",
    "Mi a valódi oka egyes betegségeknek?",
    "Mindent a véletelenek irányítanak, vagy minden okkal történik?",
    "Sokkal mélyebb összefüggések vannak életünkben, mint képzelnénk!",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Add or remove characters based on current index
        if (text.length < messages[index].length) {
          setText((prevText) => {
            return messages[index].slice(0, prevText.length + 1);
          });
        } else {
          // Finish typing, then start deleting
          clearInterval(interval);
          setTimeout(() => {
            const deleteInterval = setInterval(() => {
              setText((prevText) => {
                if (prevText.length === 0) {
                  clearInterval(deleteInterval);
                  setIndex((prevIndex) => (prevIndex + 1) % messages.length);
                  setTypingSpeed(40);
                  setDeletingSpeed(10);
                  return '';
                } else {
                  return prevText.slice(0, prevText.length - 1);
                }
              });
            }, deletingSpeed);
          }, 1000); // Wait 0.5s before deleting
        }
    }, typingSpeed);

    // Cleanup interval on component unmount or message change
    return () => clearInterval(interval);
  }, [index, messages, text, typingSpeed, deletingSpeed]);

  return (
    <div className="text-center mt-5" style={{ height: "200px", overflowY: "auto", padding: "10px", fontFamily: "Arial, sans-serif", fontSize: "25px" }}>
      <p>{text}</p>
    </div>
  );
};

export default ChatAnimation;
