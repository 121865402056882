import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { usePost } from '../auth/apicalls';
import { useQueryClient } from "react-query";
import { showErrorAlert } from "../alert/ErrorAlert";
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

const UploadButton = ({ szemelyId }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSide, setSelectedSide] = useState(''); // Legördülő menü értékének tárolása
  const queryClient = useQueryClient();

  const { mutate: uploadImage, isLoading } = usePost(
    `/api/szemely/${szemelyId}/?request_type=add-szemelykep`,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/api/szemely/${szemelyId}/`);
      },
      onError: (error) => {
        showErrorAlert({
          title: "Nem sikerült feltölteni a képet!",
          text: error.response?.data?.user_error || "Ismeretlen hiba történt.",
        });
      },
    },
    "formData"
  );

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      let processedFile = file;

      // Ha a fájl HEIC formátumú, konvertáljuk JPG-re
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const convertedBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });

        processedFile = new File([convertedBlob], file.name.replace(/\.\w+$/, '.jpg'), {
          type: 'image/jpeg',
        });
      }

      // Ha a fájl mérete nagyobb, mint 1 MB, tömörítsük
      if (processedFile.size > 1024 * 1024) {
        const compressedBlob = await imageCompression(processedFile, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        processedFile = new File(
          [compressedBlob],
          processedFile.name,
          { type: processedFile.type }
        );
      }

      setSelectedFile(processedFile);
    } catch (error) {
      showErrorAlert({
        title: "Fájl feldolgozási hiba!",
        text: "Nem sikerült a fájlt konvertálni vagy tömöríteni.",
      });
    }
  };

  const handleUpload = () => {
    if (selectedFile && selectedSide) {
      const formData = new FormData();
      formData.append('kep', selectedFile);
      formData.append('fotozasirany', selectedSide); // Legördülő menü értékének hozzáadása

      uploadImage(formData);
      setShowModal(false);
    } else {
      showErrorAlert({
        title: "Hiányzó információ!",
        text: "Kérlek, válassz egy fájlt és az fotozasirány opciót is a feltöltéshez.",
      });
    }
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Kép feltöltése
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kép feltöltése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Válassz egy képet:</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Válassz oldalt:</Form.Label>
              <Form.Control
                as="select"
                value={selectedSide}
                onChange={(e) => setSelectedSide(e.target.value)}
              >
                <option value="">-- Válassz egy opciót --</option>
                <option value="Bal profil">Bal profil</option>
                <option value="Szemből">Szemből</option>
                <option value="Jobb profil">Jobb profil</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Mégse
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={isLoading}
          >
            {isLoading ? 'Feltöltés...' : 'Feltöltés'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadButton;
