import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card, Button, Form } from 'react-bootstrap';
import AuthContext from '../../pages/userkezeles/AuthContext'
import {useFetching, usePost} from "../auth/apicalls"
import {Loading} from "../alert/Loading"
import {ErrorAlert} from "../alert/ErrorAlert"

const Test = () => {
  const { id } = useParams();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [nextLeckeId, setNextLeckeId] = useState(0);
  const [testResultMessage, setTestResultMessage] = useState('');
  const [canSubmit, setCanSubmit] = useState(true);
  const [countdown, setCountdown] = useState(0);
  const navigate = useNavigate();
  let {user} = useContext(AuthContext)
  const { data: testData, isError, isLoading } = useFetching(`/api/lecke/${id}/test`);

  const { mutate: submitTest} = usePost(`/api/teszt/${id}/submit_test/`, {
    onSuccess: (data) => {
      setTestResultMessage(data.data.message);
      setNextLeckeId(data.data.kovlecke);
  },
    onError: (error) => {
      console.error('Error submitting test results:', error);
  },
  });

  useEffect(() => {
    let timer = null;

    if (!canSubmit) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [canSubmit]);

  const handleAnswerChange = (answerId) => {
    setSelectedAnswers((prevAnswers) => {
      if (prevAnswers.includes(answerId)) {
        return prevAnswers.filter((id) => id !== answerId);
      } else {
        return [...prevAnswers, answerId];
      }
    });
  };

  const handleSubmitTest = async () => {
    try {
      setSubmitting(true);
      setCanSubmit(false);
      setCountdown(5); // Visszaszámlálás beállítása

      submitTest({
        user_id: user.user_id,
        selected_answer_ids: selectedAnswers,
      } )
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        setCanSubmit(true);
        setCountdown(0); // Visszaszámlálás nullázása
      }, 5500);
    }
  };

  const handleNextLecke = () => {
    navigate(`/lecke/${nextLeckeId}`);
  };

  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="" />}
      {testData && (
      <div>
        <h1 className='text-center mt-3'>Teszteld a tudásod!</h1>
        <p>Jelöld meg az összes választ, ami megfelel az alábbi állításnak!</p>
        <p>Egyszerre több válasz is jó lehet (akár lehet az összes jó/összes rossz is)</p>
        {testData.map((question) => (
          <Card key={question.id} className="mb-3">
            <Card.Body>
              <Card.Title>{question.kerdes}</Card.Title>
              <Form>
                {question.valaszok.map((answer) => (
                  <Form.Check
                    key={answer.id}
                    type="checkbox"
                    label={answer.szoveg}
                    onChange={() => handleAnswerChange(answer.id)}
                  />
                ))}
              </Form>
            </Card.Body>
          </Card>
        ))}
        <Button variant="success" onClick={handleSubmitTest} disabled={submitting || !canSubmit}>
          {submitting ? 'Gondolkodik...' : 'Teszt beküldése'} {countdown > 0 && `(${countdown}s)`}
        </Button>
        {testResultMessage && (
          <div style={{ marginTop: '10px', color: testResultMessage.includes('sikeres') ? 'var(--bs-success)' : 'var(--bs-danger)' }}>
            {testResultMessage}
          </div>
        )}
        {testResultMessage.includes('sikeres') && (
          <Button variant="info" onClick={handleNextLecke}>
            Következő lecke
          </Button>
        )}
        <hr/>
        <div className='text-center'>
        <Link to={`/leckefejezetek`} >
            <Button variant='primary' className='my-2 text-center'>Vissza a leckék főmenüjébe</Button>
          </Link>
        </div>
      </div>)}
    </>
  );
};

export default Test;
