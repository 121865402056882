import axios from "axios";
import {
    useQuery,
    useMutation,
  } from "react-query";

axios.defaults.withCredentials = false;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const createApiInstance = async (type, authReq) => {

    const axiosInstance = axios.create({
      headers: {
        "content-type":
          type === "json"
            ? "application/json"
            : type === "xlsx"
              ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : "multipart/form-data",
      },
      timeout: 60000 /* 1 mins*/,
    });

    axiosInstance.interceptors.request.use(
      async (response) => {
        if (authReq) {
          const accessToken = JSON.parse(localStorage.getItem('authTokens')).access

          response.headers.Authorization = `Bearer ${accessToken}`;
          if (type === "xlsx") {
            response.responseType = "blob";
          }
        }
        return response;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    return axiosInstance;
  };

// GET method
export const useFetching = (url, authReq=true, queryParam={}) => {
  const options = undefined
  const fetchData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    const axiosInstance = await createApiInstance('json', authReq);
    const result = await axiosInstance.get(url, {
      params: queryParam ? { queryParam: queryParam } : undefined,
      signal,
    });
    return result.data;
  };

  return useQuery(url, fetchData, options);
};

// POST, PUT, PATCH, DELETE methods
export const useMutateDynamic = (url, options, method, type = "json") => {
  const mutateRequest = async ({ data, dynamicUrl } = {}) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const axiosInstance = await createApiInstance(type, true);

    const requestUrl = dynamicUrl || url;

    const result = await axiosInstance[method](requestUrl, data, {
      signal,
    });
    return result;
  };

  return useMutation(mutateRequest, options);
};
export const useMutate = (url, options, method, type = "json") => {
  const mutateRequest = async (data) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const axiosInstance = await createApiInstance(type, true);

    const result = await axiosInstance[method](url, data, {
      signal,
    });
    return result;
  };

  return useMutation(mutateRequest, options);
};


export const usePost = (url, options, type = "json") => {
  return useMutate(url, options || {}, "post", type);
};

export const usePut = (url, options) => {
  return useMutate(url, options || {}, "put");
};

export const usePatch = (url, options) => {
  return useMutate(url, options || {}, "patch");
};

export const useDelete = (url, options) => {
  return useMutate(url, options || {}, "delete");
};

export const useDeleteDynamic = (url, options) => {
  return useMutateDynamic(url, options || {}, "delete");
};


