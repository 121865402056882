// src/components/NewsFeed.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import HirFeltoltes from './HirFeltoltes';
import { ActionButtonGroup } from './ActionButtonGroup';
import { KommentFeltoltes } from "./KommentFeltoltes"
import { useFetching } from "../../auth/apicalls";
import { Loading } from "../../alert/Loading";
import { ErrorAlert } from "../../alert/ErrorAlert";
import { usePermissions } from '../../userkezeles/PermissionContext';

const renderCardText = (text) => {
  const lineBreakRegex = /(\r\n|\n|\r)/g;
  const formattedText = text.replace(lineBreakRegex, '<br>');

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return formattedText.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

const NewsFeed = () => {
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [activePostId, setActivePostId] = useState(null);
  const [expandedComments, setExpandedComments] = useState({});
  const [aktivtipus, setAktivtipus] = useState("all");
  const { posztLetrehozasJog } = usePermissions();
  const { data: newsdata, isError, isLoading } = useFetching('/api/hir/');

  useEffect(() => {
    const fetchPosts = async () => {
      if (newsdata) {
        setNews(newsdata);
        setFilteredNews(newsdata.slice(0, 20));
      }
    };
    fetchPosts();
  }, [newsdata]);

  const handleTypeFilter = (type) => {
    setAktivtipus(type);
    if (type === "all") {
      setFilteredNews(news.slice(0, 20));
    } else {
      const filtered = news.filter((post) => post.tipus === type);
      setFilteredNews(filtered);
    }
  };

  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="" />}
      {newsdata && (
        <div className="mt-3 row card border-0" style={{backgroundColor: "var(--bs-vilagoskek)"}}>
          <div className="card-body">
            <h1 className="card-title text-center" style={{ color: "var(--bs-text-spring)" }} >Közösség</h1>
            <Navbar bg="light" expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link onClick={() => handleTypeFilter("all")} active={aktivtipus === "all"}>Legutóbbi 20</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("elvi")} active={aktivtipus === "elvi"}>Elvi kérdések</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("gyakorlati")} active={aktivtipus === "gyakorlati"}>Gyakorlati kérdések</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("altalanos")} active={aktivtipus === "altalanos"}>Általános kérdések</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("rendszer")} active={aktivtipus === "rendszer"}>Új fejlesztések</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("kozhir")} active={aktivtipus === "kozhir"}>Közérdekű hír</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("mem")} active={aktivtipus === "mem"}>Vicces</Nav.Link>
                  <Nav.Link onClick={() => handleTypeFilter("hirdetesek")} active={aktivtipus === "hirdetesek"}>Hirdetések</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            {posztLetrehozasJog && <HirFeltoltes />}

            <div className="">
              {filteredNews && filteredNews.map((post, index) => (
                <div className="card mt-3 border-0" key={index}>
                  <div className="card-body">
                    <div className="row">
                    <div className="col-8 mb-1" style={{ color: 'var(--bs-elenkrozsa)', fontWeight: 'bold',fontSize: "0.9rem" }}>
                        @{post.user ? post.user : 'rendszer'}
                      </div>
                      <div className="col-4 d-flex flex-column justify-content-end align-items-end">
                        <div className="text-secondary" style={{fontSize: "0.7rem"}}>
                          {post.datum}
                        </div>
                      </div>
                    </div>
                    <div className="col-9">
                        <h2 className="card-title" style={{fontSize: "1.1rem"}}>{post.cim}</h2>
                      </div>

                    <div className="card-text" style={{fontSize: "0.9rem"}} dangerouslySetInnerHTML={{ __html: renderCardText(post.szoveg) }} />

                    {post.kep && (
                      <img
                        src={post.kep}
                        alt="post kep"
                        className="img-fluid"
                      />
                    )}
                    <hr/>
                    <ActionButtonGroup {...{ post, setActivePostId, expandedComments, setExpandedComments }} />

                    <KommentFeltoltes {...{ activePostId, post, setActivePostId, news, setNews }} />

                    {expandedComments[post.id] && post.hozzaszolasok && post.hozzaszolasok.map((comment, commentIndex) => (
                      <div className='card border-0 mt-2 p-1' style={{backgroundColor: "rgb(107 121 158 / 22%)"}} key={commentIndex} >
                        <div style={{ color: 'var(--bs-dark)', fontWeight: 'bold', fontSize: "0.9rem" }}>
                        @{comment.user}
                        </div>
                        <div className="card-text rounded mx-2" style={{fontSize: "0.8rem"}}>
                          {comment.szoveg}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default NewsFeed;
