import React, { useState } from 'react';
import './ErtekeloSav.css'; // CSS fájl importálása

function ErtekeloSav({
  isTwoWayScale,
  ertekeles,
  onRatingSelect,
  side,
}) {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    ertekeles !== null ? (isTwoWayScale ? ertekeles + 3 : ertekeles) : null
  );

  const circleCount = isTwoWayScale ? 7 : 4;
  const middleIndex = isTwoWayScale ? 3 : 0;

  const handleSelect = (index) => {
    const rating = isTwoWayScale ? index - 3 : index;
    setSelectedIndex(index);
    onRatingSelect(rating, side);
  };

  const getTooltipText = (index) => {
    const delta = Math.abs(index - middleIndex);
    if (delta === 3) return "Kifejezetten/feltűnően";
    if (delta === 2) return "Közepesen/láthatóan";
    if (delta === 1) return "Enyhén/épphogy";
    if (delta === 0) return isTwoWayScale ? "Kiegyensúlyozott" : "Nincs";
    return "";
  };

  return (
    <div className="scale-container">
      {[...Array(circleCount)].map((_, index) => {
        const size = 20 + Math.abs(index - middleIndex) * 10;
        const color = isTwoWayScale
          ? index < middleIndex
            ? `var(--bs-danger)` // Piros szín a bal oldali körökhöz
            : index > middleIndex
            ? `var(--bs-vilagoskek)` // Kék szín a jobb oldali körökhöz
            : `var(--bs-secondary)` // Semleges szín a középső körhöz
          : index === 0
          ? `var(--bs-secondary)` // Semleges szín az első körhöz
          : `var(--bs-vilagoskek)`; // Kék szín a többi körhöz

        const isDisabled =
          ertekeles !== null &&
          (isTwoWayScale ? index === ertekeles + 3 : index === ertekeles);

        return (
          <div
            key={index}
            className={`circle-wrapper`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
          >
            <button
              className={`scale-circle ${
                hoverIndex === index || selectedIndex === index ? 'active' : ''
              } ${ertekeles !== null ? 'faded' : ''} ${isDisabled ? 'disabled' : ''}`}
              style={{
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: color,
              }}
              onClick={() => handleSelect(index, side)}
              disabled={isDisabled}
            >
              {isDisabled && (
                <span
                  className="icon"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: `${size * 0.4}px`,
                    color: 'var(--bs-light)', // Fehér pipajel
                  }}
                >
                  ✓
                </span>
              )}
            </button>
            <div
              className={`tooltip ${
                hoverIndex === index || selectedIndex === index ? 'visible' : ''
              }`}
            >
              {getTooltipText(index)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ErtekeloSav;
