import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import AuthContext from '../userkezeles/AuthContext';
import { StartIcon } from '../../utils/Ikonok';
import  AddLeckeModal  from './AddLeckeModal';

const FejezetElemek = ({ fejezetList, setFejezetList, setIsEditing, setEditFejezetId }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [newLeckeCim, setNewLeckeCim] = useState('');
  const [fejezetIdToAddLecke, setFejezetIdToAddLecke] = useState(null);

  const deleteFejezet = async (fejezetId) => {
    try {
      await axios.delete(`/api/fejezet/${fejezetId}/`);
      setFejezetList(fejezetList.filter((fejezet) => fejezet.id !== fejezetId));
    } catch (error) {
      console.error('Error deleting Fejezet:', error);
    }
  };

  const groupedLeckeList = fejezetList.reduce((acc, fejezet) => {
    if (!acc[fejezet.cim]) {
      acc[fejezet.cim] = {
        id: fejezet.id,
        szukseges_szint: fejezet.szukseges_szint,
        leckek: [],
      };
    }

    if (fejezet.leckek.length === 0) {
      acc[fejezet.cim].leckek.push({
        id: null,
        cim: '',
        teljesitett: false,
        pontszam: null,
        szukseges_szint: fejezet.szukseges_szint,
      });
    } else {
      fejezet.leckek.forEach((lecke) => {
        acc[fejezet.cim].leckek.push(lecke);
      });
    }

    return acc;
  }, {});

  const handleEditClick = (fejezetId) => {
    setIsEditing(true);
    setEditFejezetId(fejezetId);
  };

  const handleAddLeckeClick = (fejezetId) => {
    setFejezetIdToAddLecke(fejezetId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setFejezetIdToAddLecke(null);
    setNewLeckeCim('');
    setShowModal(false);
  };

  const handleCreateLecke = async () => {
    try {
      const response = await axios.post(`/api/fejezet/${fejezetIdToAddLecke}/lecke`, {
        cim: newLeckeCim,
        // Egyéb lecke tulajdonságok, amiket a szerver vár
      });
      const createdLecke = response.data;
      // Frissítjük a leckék listáját a hozzáadott leckével
      const updatedFejezetList = fejezetList.map((fejezet) => {
        if (fejezet.id === fejezetIdToAddLecke) {
          return {
            ...fejezet,
            leckek: [...fejezet.leckek, createdLecke],
          };
        }
        return fejezet;
      });
      setFejezetList(updatedFejezetList);
      handleCloseModal();
    } catch (error) {
      console.error('Error creating Lecke:', error);
    }
  };

  return (
    <>
      {Object.entries(groupedLeckeList).map(([fejezetCim, { id: fejezetId, szukseges_szint, leckek }]) => (
        <>
          {user.felhasznaloi_szint >= szukseges_szint && (
            <div key={fejezetId}>
              <h2 className='mt-1'>{fejezetCim}
                {(user.role === "Admin" || user.role === "Szerkesztő") && (
                  <>
                    <Button variant="info" onClick={() => handleEditClick(fejezetId)}>Edit</Button>
                    <Button variant="danger" onClick={() => deleteFejezet(fejezetId)}>Delete</Button>
                    <Button variant="success" onClick={() => handleAddLeckeClick(fejezetId)}>Add Lecke</Button>
                  </>
                )}
              </h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Lecke</th>
                    <th>Teljesítés</th>
                    <th>Elérhető pontok</th>
                  </tr>
                </thead>
                <tbody>
                  {leckek.map((lecke) => (
                    <tr key={lecke.id}>
                      <td>
                        <Link to={`/lecke/${lecke.id}`}>
                          <Button variant="primary"><StartIcon /></Button>
                        </Link>
                      </td>
                      <td>{lecke.cim}</td>
                      <td>
                        {lecke.teljesitett && (
                          <span style={{ color: 'var(--bs-dark)' }}>✔️</span>
                        )}
                      </td>
                      <td>{lecke.pontszam}</td>
                    </tr>
                  ))}
                  {(user.role === "Admin" || user.role === "Szerkesztő") && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <Button variant="success" onClick={() => handleAddLeckeClick(fejezetId)}>Új Lecke Hozzáadása</Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </>
      ))}
      <AddLeckeModal show={showModal} onHide={handleCloseModal}/>
      </>
    );
  };

  export default FejezetElemek;
